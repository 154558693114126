import React, { useState } from "react";
import Footer from "./Footer";

const appContainerStyle = {
  backgroundColor: "#060316",
  color: "#ffffff",
  width: "80%",
  maxWidth: "2200px",
  padding: "40px",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
  marginTop: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "80vh",
  margin: "auto",
};

const jsonContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  marginBottom: "30px",
  justifyContent: "space-between",
  width: "100%",
};

const jsonInputStyle = {
  width: "48%",
  height: "500px",
  padding: "20px",
  fontSize: "14px",
  fontFamily: "monospace",
  border: "2px solid #ccc",
  borderRadius: "8px",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  backgroundColor: "#fafafa",
  overflowY: "auto",
};

const jsonOutputStyle = {
  width: "48%",
  height: "500px",
  padding: "20px",
  border: "2px solid #ccc",
  borderRadius: "8px",
  fontFamily: "monospace",
  fontSize: "14px",
  backgroundColor: "#f9f9f9",
  overflowY: "auto",
  color: "#000",
};

const caminhoStyle = {
  width: "48%",
  height: "20px",
  overflowY: "auto",
  padding: "30px",
  border: "2px solid #ccc",
  borderRadius: "8px",
  fontFamily: "monospace",
  fontSize: "14px",
  backgroundColor: "#f9f9f9",
  color: "#000",
  textAlign: "center",
  marginBottom: "60px",
};

const inputStyle = {
  width: "97%",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  backgroundColor: "#f4f4f4",
  padding: "6px 6px 0",
  borderRadius: "5px",
  border: "1px solid #ccc",
  fontFamily: "monospace",
  fontSize: "14px",
  color: "#000",
  textDecoration: "none",
  height: "85%",
  overflowY: "auto",
  resize: "none",
};

const searchInputStyle = {
  flex: 1,
  padding: "10px",
  marginLeft: "400px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  fontFamily: "monospace",
  fontSize: "14px",
};

const buttonStyle = {
  backgroundColor: "#4444aa",
  color: "white",
  fontSize: "16px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  width: "100%",
  marginBottom: "20px",
};

const clickableStyle = {
  color: "blue",
  cursor: "pointer",
};

const highlightStyle = {
  backgroundColor: "yellow",
};

const renderJson = (obj, path = "", handleClick, searchTerm, level = 0) => {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return (
        <div>
          <div style={{ marginLeft: `${level * 20}px` }}>[</div>
          {obj.map((item, index) => (
            <div key={`${path}[${index}]`} style={{ marginLeft: `${(level + 1) * 20}px` }}>
              {renderJson(item, `${path}[${index}]`, handleClick, searchTerm, level + 1)}
              {index < obj.length - 1 && ","}
            </div>
          ))}
          <div style={{ marginLeft: `${level * 20}px` }}>]</div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ marginLeft: `${level * 20}px` }}>{"{"}</div>
          {Object.entries(obj).map(([key, value], index) => {
            const isHighlighted = searchTerm && JSON.stringify(value).includes(searchTerm);
            return (
              <div key={`${path}.${key}`} style={{ marginLeft: `${(level + 1) * 20}px`, ...(isHighlighted ? highlightStyle : {}) }}>
                <span
                  style={clickableStyle}
                  onClick={() => handleClick(path ? `${path}.${key}` : key)}
                >
                  {`"${key}"`}
                </span>: {renderJson(value, path ? `${path}.${key}` : key, handleClick, searchTerm, level + 1)}
                {index < Object.entries(obj).length - 1 && ","}
              </div>
            );
          })}
          <div style={{ marginLeft: `${level * 20}px` }}>{"}"}</div>
        </div>
      );
    }
  } else {
    const isHighlighted = searchTerm && JSON.stringify(obj).includes(searchTerm);
    return <span style={isHighlighted ? highlightStyle : {}}>{JSON.stringify(obj)}</span>;
  }
};

function App() {
  const [jsonInput, setJsonInput] = useState("");
  const [formattedJson, setFormattedJson] = useState(null);
  const [selectedPath, setSelectedPath] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const formatJson = () => {
    try {
      const json = JSON.parse(jsonInput);
      setFormattedJson(json);
    } catch (error) {
      alert("JSON inválido! Verifique a sintaxe.");
    }
  };

  const handleClick = (path) => {
    setSelectedPath(path);
  };

  return (
    <div>
      <div style={{ backgroundColor: "#060316", minHeight: "100vh" }}>
        <div style={appContainerStyle}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <h1 style={{ textAlign: "center", marginBottom: "20px", color: "white", flex: 1 }}>
              Formatador de JSON
            </h1>
          </div>
          <div style={jsonContainerStyle}>
            <div style={jsonInputStyle}>
              <h2 style={{ color: "black" }}>JSON Compacto</h2>
              <textarea
                value={jsonInput}
                onChange={(e) => setJsonInput(e.target.value)}
                placeholder="Cole o JSON compactado aqui"
                style={inputStyle}
              />
            </div>
            <div style={jsonOutputStyle}>
              <h2 style={{display: "inline-block"}}>JSON Formatado</h2>
              <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Pesquisar valor..."
              style={{...searchInputStyle, display: "inline-block"}}
            />
              <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", overflowY: "auto", height: "85%", backgroundColor: "#f4f4f4", border: "1px solid #ccc", borderRadius: "4px" }}>
                {formattedJson && renderJson(formattedJson, "", handleClick, searchTerm)}
              </pre>
              
            </div>
          </div>
          <button onClick={formatJson} style={buttonStyle}>
            Formatar JSON
          </button>
          <h2>Caminho Selecionado:</h2>
          <div style={caminhoStyle}>{selectedPath}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;