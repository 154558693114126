import React, { useState } from "react";
import Footer from "./Footer";
import { CgKey } from "react-icons/cg";
import { CgCreditCard } from "react-icons/cg";
import { CgCheck } from "react-icons/cg";

const Welcome = () => {
  const [formData, setFormData] = useState({
    token: "",
    phoneNumberId: "",
    recipientPhone: "",
    selectedType: "", // Novo estado para controlar o tipo selecionado
    templateName: "", // Novo estado para o nome do template
    language: "en_US", // Novo estado para a linguagem
  });

  const [responseMessage, setResponseMessage] = useState({ visible: false, text: "", success: false });

  const [tokenValidationColor, setTokenValidationColor] = useState('#ccc'); // Cor inicial do quadrado
  const [paymentValidationColor, setPaymentValidationColor] = useState('#ccc'); // Cor inicial do quadrado
  const [sendIconColor, setSendIconColor] = useState('#ccc'); // Cor inicial do ícone de envio

  const [checkPayment, setCheckPayment] = useState(true); // Estado para controlar se a verificação de pagamento deve ser feita

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      selectedType: prev.selectedType === value ? "" : value,
      // Limpa o nome do template se "Mensagem" for desmarcado
      templateName: prev.selectedType === "mensagem" ? "" : prev.templateName,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token, phoneNumberId, recipientPhone, selectedType, templateName, language } = formData;

    const sanitizedPhone = recipientPhone.replace(/\D/g, "");

    const webhookUrl =
      "https://webhook.sellflux.com/webhook/v2/form/lead/f2fa82b33410c3a3253129ab6fcc0a47?redirect_url=https%3A%2F%2Fsellflux.com.br";

    try {
      // Verificar se o token está válido antes de continuar
      const isTokenValid = await verifyTokenAndUpdateColor(token);
      if (!isTokenValid) {
        setSendIconColor('#dc3545'); // Define o ícone de envio como vermelho
        throw new Error(`Token inválido ou expirado.`);
      }

      // Verificar se o método de pagamento está válido, se a verificação estiver ativada
      if (checkPayment) {
        const isPaymentValid = await verifyPaymentAndUpdateColor(token);
        if (!isPaymentValid) {
          setSendIconColor('#dc3545'); // Define o ícone de envio como vermelho
          throw new Error(`Método de pagamento inválido.`);
        }
      }

      // Enviar webhook
      await fetch(webhookUrl, {
        method: "POST",
        body: new URLSearchParams({ email: "placeholder@example.com" }), // Placeholder para email
      });

      // Enviar template ou mensagem
      let data;
      if (selectedType === "template") {
        data = {
          messaging_product: "whatsapp",
          to: sanitizedPhone,
          type: selectedType,
          template: {
            name: "hello_world", // Nome do template fixo
            language: { code: language }, // Usar a linguagem selecionada
          },
        };
      } else if (selectedType === "mensagem") {
        data = {
          messaging_product: "whatsapp",
          to: sanitizedPhone,
          type: selectedType,
          template: {
            name: templateName, // Nome do template fornecido pelo usuário
            language: { code: language }, // Usar a linguagem selecionada
          },
        };
      }

      const response = await fetch(`https://graph.facebook.com/v20.0/${phoneNumberId}/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        setResponseMessage({ visible: true, text: `Sucesso ao enviar ${selectedType} para o número ${recipientPhone}!`, success: true });
        setSendIconColor('#28a745'); // Define o ícone de envio como verde apenas se o template for enviado com sucesso
      } else {
        const errorMessage = handleApiError(result);
        setResponseMessage({ visible: true, text: errorMessage, success: false });
        setSendIconColor('#dc3545'); // Define o ícone de envio como vermelho em caso de erro
      }
    } catch (error) {
      setResponseMessage({ visible: true, text: `Ocorreu um erro!\nMensagem: ${error.message}`, success: false });
      setSendIconColor('#dc3545'); // Define o ícone de envio como vermelho em caso de erro
    }
  };

  const verifyTokenAndUpdateColor = async (token) => {
    try {
      const response = await fetch(`https://graph.facebook.com/debug_token?input_token=${token}&access_token=${token}`);
      const result = await response.json();

      if (result.data.is_valid) {
        setTokenValidationColor('#28a745'); // Verde para token válido
      } else {
        setTokenValidationColor('#dc3545'); // Vermelho para token inválido
      }

      return result.data.is_valid;
    } catch (error) {
      console.error("Erro ao verificar o token:", error);
      setTokenValidationColor('#dc3545'); // Vermelho para erro
      return false;
    }
  };

  const verifyPaymentAndUpdateColor = async (token) => {
    try {
      const response = await fetch(`https://graph.facebook.com/payment_methods?access_token=${token}`);
      const result = await response.json();

      if (result.data.is_valid) {
        setPaymentValidationColor('#28a745'); // Verde para método de pagamento válido
      } else {
        setPaymentValidationColor('#dc3545'); // Vermelho para método de pagamento inválido
      }

      return result.data.is_valid;
    } catch (error) {
      console.error("Erro ao verificar o método de pagamento:", error);
      setPaymentValidationColor('#dc3545'); // Vermelho para erro
      return false;
    }
  };

  const handleApiError = (errorData) => {
    const errorCode = errorData.error.code;
    let formattedMessage = '';

    switch (errorCode) {
      case 4:
        formattedMessage = 'O app atingiu o limite da taxa de chamadas de API.';
        break;
      case 0:
        formattedMessage = "Não foi possível autenticar o usuário do app. Geralmente, isso significa que o token de acesso incluído expirou, foi invalidado ou que o usuário do app alterou uma configuração para impedir que todos os apps acessem os dados dele.";
        break;
      case 33:
        formattedMessage = 'O número de telefone comercial foi excluído.';
        break;
      case 100:
        formattedMessage = 'Erro: Parâmetro inválido.';
        break;
      case 190:
        formattedMessage = 'Token expirado ou inválido, revise o token e tente novamente.';
        break;
      case 200:
        formattedMessage = 'Erro: Acesso não autorizado.';
        break;
      case 368:
        formattedMessage = 'A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma.';
        break;
      case 400:
        formattedMessage = 'Erro: Requisição malformada.';
        break;
      case 401:
        formattedMessage = 'Erro: Token de acesso inválido.';
        break;
      case 403:
        formattedMessage = 'Erro: Acesso proibido.';
        break;
      case 404:
        formattedMessage = 'Erro: Recurso não encontrado.';
        break;
      case 500:
        formattedMessage = 'Erro: Erro interno do servidor.';
        break;
      case 503:
        formattedMessage = 'Erro: Serviço indisponível.';
        break;
      case 80007:
        formattedMessage = 'A conta do WhatsApp Business atingiu o limite de volume. Tente novamente mais tarde ou reduza a frequência ou a quantidade de consultas da API.';
        break;
      case 131048:
        formattedMessage = 'Falha ao enviar a mensagem devido a um limite de envios que podem ser feitos deste número de telefone.';
        break;
      case 131031:
        formattedMessage = 'A conta do WhatsApp Business associada ao app foi restringida ou desabilitada por violar uma política da plataforma.';
        break;
      case 131042:
        formattedMessage = 'Ocorreu um erro relacionado à forma de pagamento.';
        break;
      case 131045:
        formattedMessage = 'Ocorreu uma falha ao enviar a mensagem devido a um erro de registro do número de telefone.';
        break;
      case 131049:
        formattedMessage = 'A Meta não quer entregar essa mensagem devido a manter o engajamento saudável do ecossistema.';
        break;
      case 132001:
        formattedMessage = 'O modelo não existe no idioma especificado ou não foi aprovado.';
        break;
      case 133005:
        formattedMessage = 'O PIN de confirmação em duas etapas está incorreto.';
        break;
      default:
        formattedMessage = 'Erro desconhecido.';
    }

    return `Código: ${errorCode}\nMensagem: ${formattedMessage}`;
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#060316",
      color: "white",
    },
    form: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      maxWidth: "800px",
      width: "100%",
      color: "black",
    },
    input: {
      width: "97%",
      padding: "10px",
      margin: "10px 0",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      width: "100%",
      padding: "10px",
      margin: "10px 0",
      backgroundColor: "#4444aa",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    checkboxContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "6px 0",
    },
    checkbox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "10px",
    },
    checkboxInput: {
      width: "20px",
      height: "20px",
      cursor: "pointer",
      accentColor: "#4444aa",
    },
    checkboxInputDisable: {
      width: "20px",
      height: "20px",
      pointerEvents: 'none', // Bloqueia todos os eventos de ponteiro
      opacity: '0.5',
      accentColor: "#4444aa", // Cor do checkbox quando marcado
      cursor: "not-allowed",
    },
    responseMessage: (success) => ({
      marginTop: "15px",
      padding: "10px",
      backgroundColor: success ? "#d4edda" : "#f8d7da",
      color: success ? "#155724" : "#721c24",
      border: success ? "1px solid #c3e6cb" : "1px solid #f5c6cb",
      borderRadius: "5px",
      whiteSpace: "pre-line", // Para garantir que as quebras de linha sejam respeitadas
    }),
    inlineContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    select: {
      width: "20%", // Largura do seletor de linguagem
      marginRight: '10px'
    },
    templateInput: {
      width: "80%", // Largura do campo de nome do template
    },
    validationSquare: {
      width: "50px",
      height: "50px",
      color: tokenValidationColor,
      marginTop: "10px",
      marginLeft: "50px",
      marginRight: "50px",
      cursor: tokenValidationColor === '#28a745' ? 'pointer' : 'default', // Mudar o cursor para ponteiro apenas para tokens válidos
    },
    validationPaymentoSquare: {
      width: "50px",
      height: "50px",
      color: paymentValidationColor,
      marginTop: "10px",
      marginLeft: "50px",
      marginRight: "50px",
      cursor: paymentValidationColor === '#28a745' ? 'pointer' : 'default', // Mudar o cursor para ponteiro apenas para tokens válidos
    },
    iconContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
    },
    iconRow: {
      display: "flex",
      justifyContent: "center",
      gap: "200px",
    },
    icon: {
      width: "50px",
      height: "50px",
      color: tokenValidationColor,
      cursor: tokenValidationColor === "#28a745" ? "pointer" : "default",
    },
    iconPayment: {
      width: "50px",
      height: "50px",
      color: paymentValidationColor,
      cursor: paymentValidationColor === "#28a745" ? "pointer" : "default",
    },
    sendIcon: {
      width: "50px",
      height: "50px",
      color: sendIconColor,
      cursor: sendIconColor === "#28a745" ? "pointer" : "default",
    }
  }

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
        <h2>Envie um template simples</h2>
        <label htmlFor="token">Token de acesso:</label>
        <input
          type="text"
          id="token"
          style={styles.input}
          placeholder="Digite o Token"
          value={formData.token}
          onChange={handleChange}
          required
        />

        <label htmlFor="phoneNumberId">ID do dispositivo:</label>
        <input
          type="text"
          id="phoneNumberId"
          style={styles.input}
          placeholder="Identificação do número de telefone"
          value={formData.phoneNumberId}
          onChange={handleChange}
          required
        />

        <label>Selecione o tipo:</label>
        <div style={styles.checkboxContainer}>
          <div style={styles.checkbox}>
            <span>Hello World</span>
            <input
              type="checkbox"
              value="template"
              checked={formData.selectedType === "template"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInput}
            />
          </div>
          <div style={styles.checkbox}>
            <span>Template</span>
            <input
              type="checkbox"
              value="mensagem"
              checked={formData.selectedType === "mensagem"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInput}
            />
          </div>
          <div style={styles.checkbox}>
            <span>Imagem</span>
            <input
              type="checkbox"
              value="imagem"
              checked={formData.selectedType === "imagem"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInputDisable}
            />
          </div>
          <div style={styles.checkbox}>
            <span>Vídeo</span>
            <input
              type="checkbox"
              value="video"
              checked={formData.selectedType === "video"}
              onChange={handleCheckboxChange}
              style={styles.checkboxInputDisable}
            />
          </div>
        </div>

        {formData.selectedType === "mensagem" && (
          <>
            <label htmlFor="language" style={{ marginRight: "10px", width: "20%" }}>Selecione a Linguagem:</label>
            <div style={styles.inlineContainer}>

              <select
                id="language"
                style={{ ...styles.input, ...styles.select }} // Largura do seletor de linguagem
                value={formData.language}
                onChange={handleChange}
              >
                <option value="pt_BR" selected>Português (Brasil)</option>
                <option value="en_US">Inglês (EUA)</option>
                <option value="pt_PT">Português (Portugal)</option>
                <option value="es_ES">Espanhol (Espanha)</option>
                <option value="fr_FR">Francês (França)</option>
              </select>
              <input
                type="text"
                id="templateName"
                style={{ ...styles.input, ...styles.templateInput }} // Largura do campo de nome do template
                placeholder="Digite o nome do template"
                value={formData.templateName}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}

        <label htmlFor="recipientPhone">Número receptor do template:</label>
        <input
          type="text"
          id="recipientPhone"
          style={styles.input}
          placeholder="Ex: 5551990816730"
          pattern="\d{12,13}"
          value={formData.recipientPhone}
          onChange={handleChange}
          required
        />

        <label>
          <input
            type="checkbox"
            checked={checkPayment}
            onChange={() => setCheckPayment(!checkPayment)}
          />
          Ativar verificação de pagamento
        </label>

        <button type="submit" style={styles.button}>
          Enviar
        </button>

        {responseMessage.visible && (
          <div style={styles.responseMessage(responseMessage.success)}>{responseMessage.text}</div>
        )}
        <div style={styles.iconRow}>
          <div style={styles.iconContainer}>
            <p>Token</p>
            <CgKey style={styles.icon} title={tokenValidationColor === '#28a745' ? `O token é valido! Seguindo...` : 'Token inválido ou erro ao verificar'} />
          </div>
          <div style={styles.iconContainer}>
            <p>Método de pagamento</p>
            <CgCreditCard style={styles.iconPayment} title={paymentValidationColor === '#28a745' ? `Método de pagamento válido! Seguindo...` : 'Método de pagamento inválido ou erro ao verificar'} />
          </div>
          <div style={styles.iconContainer}>
            <p>Envio</p>
            <CgCheck style={styles.sendIcon} title={sendIconColor === '#28a745' ? `Template enviado com sucesso!` : 'Erro ao enviar o template'} />
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default Welcome;